/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import bgImage1 from '../images/backgrounds/bg1.jpg';
import bgImage2 from '../images/backgrounds/bg2.jpg';
import minna from '../images/minna.png';

const AboutPage = () => {
    useEffect(() => {
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)) {
            $('.image-wrapper').addClass('mobile');
        }
    });

  return (
    <Layout>
      <div
        className="wrapper image-wrapper bg-image inverse-text"
        data-image-src="style/images/art/bg3.jpg"
        css={css`
            background-image: url('${bgImage1}');
        `}
      >
        <div className="container inner pt-120 pb-120 text-center">
          <h1 className="heading mb-0">Hvem er bag linsen?</h1>
        </div>
      </div>
      <div className="wrapper light-wrapper">
        <div className="container inner">
          <div className="row">
            <div className="col-lg-6 text-md-center">
              <figure>
                <img src={minna} alt="" />
              </figure>
            </div>
            <div className="space30 d-block d-lg-none d-xl-none"></div>
            <div className="col-lg-6">
              <p>
                Mit navn er Minna og siden jeg var 13 år, har jeg dyrket min store interesse indenfor fotografering.
                Jeg arbejder bredt indenfor faget, lige fra produktbilleder til familie portrætter.
                Jeg elsker at fordybe mig, undersøge og hele processen i at få skabt det helt rigtige billede.
              </p>
              {/*<blockquote className="bordered">*/}
              {/*  <p>*/}
              {/*    Sed posuere consectetur est at lobortis. Lorem ipsum dolor sit*/}
              {/*    amet, consectetur adipiscing elit. Duis mollis, est non*/}
              {/*    commodo luctus, nisi erat porttitor ligula lacinia odio sem*/}
              {/*    nec elit.*/}
              {/*  </p>*/}
              {/*  <footer className="blockquote-footer">Connor Gibson</footer>*/}
              {/*</blockquote>*/}
              <p>
                Jeg ser frem til at samarbejde med dig/jer og skabe uforglemmelige billeder.
                Du er altid velkommen til at kontakte mig for spørgsmål eller tilbud.
                <br/>
                Jeg besvarer mine mails så hurtigt som det er muligt.
              </p>
              <ul className="social social-color social-s mb-15">
                <li>
                  <a target='_blank' href="https://www.instagram.com/minnahansenphotography/">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper gray-wrapper">
        <div className="container inner">
          <h2 className="section-title mb-40 text-center">Min process</h2>
          <div className="row gutter-50 process-wrapper arrow text-center">
            <div className="col-md-3">
              {" "}
              <span className="icon icon-bg bg-default mb-20">
                <span className="number">01</span>
              </span>
              <h5>Forberedelse</h5>
              <p>
                Vi tager en snak om opgaven og hvad dine forventinger er.
              </p>
            </div>
            <div className="col-md-3">
              {" "}
              <span className="icon icon-bg bg-default mb-20">
                <span className="number">02</span>
              </span>
              <h5>Fotosession</h5>
              <p>
                Jeg skyder billederne, enten på location eller i studiet.
              </p>
            </div>
            <div className="space20 d-md-none clearfix"></div>
            <div className="col-md-3">
              {" "}
              <span className="icon icon-bg bg-default mb-20">
                <span className="number">03</span>
              </span>
              <h5>Efterbehandling</h5>
              <p>
                Billederne efterbehandles så de lever op til den forventede kvalitet og udseende.
              </p>
            </div>
            <div className="col-md-3">
              {" "}
              <span className="icon icon-bg bg-default mb-20">
                <span className="number">04</span>
              </span>
              <h5>Afslutning</h5>
              <p>
                Til sidst vil du modtage billederne som aftalt, klar til brug.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="wrapper image-wrapper bg-image inverse-text"
        data-image-src="style/images/art/bg1.jpg"
        css={css`
            background-image: url('${bgImage2}');
        `}
      >
        <div className="container inner pt-120 pb-120">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="swiper-container-wrapper basic-slider">
                <div className="swiper-container text-center">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <blockquote className="icon icon-top larger">
                        <p>
                          Der er ingen regler for gode fotografier, der er kun gode billeder.
                        </p>
                        {/* <footer className="blockquote-footer">
                          Connor Gibson
                        </footer> */}
                      </blockquote>
                    </div>
                    {/* <div className="swiper-slide">
                      <blockquote className="icon icon-top larger">
                        <p>
                          Vivamus sagittis lacus vel augue laoreet rutrum
                          faucibus dolor auctor. Vestibulum id ligula porta
                          felis euismod semper. Cras justo odio.
                        </p>
                        <footer className="blockquote-footer">
                          Coriss Ambady
                        </footer>
                      </blockquote>
                    </div>
                    <div className="swiper-slide">
                      <blockquote className="icon icon-top larger">
                        <p>
                          Duis mollis, est non commodo luctus, nisi erat
                          porttitor ligula, eget lacinia odio sem nec elit. Cras
                          justo odio, dapibus ac facilisis.
                        </p>
                        <footer className="blockquote-footer">
                          Barclay Widerski
                        </footer>
                      </blockquote>
                    </div> */}
                  </div>
                </div>
                <div className="swiper-pagination gap-small"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
